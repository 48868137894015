import styled from '@emotion/styled';
import AboutTopSustainability from 'assets/medias/abouttop_sustainability.mp4';
import { Arrow } from 'components/atoms/arrow';
import Img from 'components/atoms/img';
import { Text } from 'components/atoms/text';
import { IndexLink } from 'components/molecules/indexLink';
import { AboutNav } from 'components/organisms/aboutNav';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeDownSection from 'components/organisms/fadeDownSection';
import FadeUpSection from 'components/organisms/fadeUpSection';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { breakpoint, mq } from 'style/variables';
import SEO from 'util/seo';
import { Parallax } from 'react-scroll-parallax';
import { EnHeading } from 'components/atoms/enHeading';

const links = [
  { name: 'Company', link: '/en/about/company' },
  { name: 'Studios', link: '/en/about/studios' },
  { name: 'Vision', link: '/en/about/vision' },
  { name: 'Culture', link: '/en/about/culture' },
  { name: 'Sustainability', link: '/en/about/sustainability' },
];

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

// const MainHeading = styled(JaHeading)``;
const Body = styled.div`
  overflow-x: hidden;
`;

const MainText = styled.div`
  margin-top: 36px;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
  }
  p {
    font-family: ${fonts.jaGothic};
    & + p {
      margin-top: 2em;
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;

const AboutSection = styled.div`
  margin-top: 50px;
  max-width: 1860px;
`;
const NewAboutText = styled(Text)`
  ${text(15, 2)};
  ${mq.onlypc} {
    margin-right: -15px;
  }
`;
const AboutContentSection = styled.div`
  position: relative;
  ${mq.onlypc} {
    display: flex;
    max-width: 1160px;
  }

  ${mq.onlysp} {
    max-width: 311px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }
`;
const AboutContentInnerEven = styled.div`
  ${mq.onlypc} {
    display: flex;
    flex-direction: row;
  }
`;
const AboutContentInnerOdd = styled.div`
  ${mq.onlypc} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const AboutLink = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: auto;
  font-family: ${fonts.enGothic};

  letter-spacing: 0.0666em;
  cursor: pointer;
  ${mq.onlypc} {
    ${text(18, 1.8)};
    margin-top: 39px;
  }
  ${mq.onlysp} {
    ${text(18, 2.1)};
    margin-top: 41px;
  }
`;

const AboutImageSection = styled.div`
  position: relative;
  ${mq.onlysp} {
    max-width: 311px;
    margin-left: -32px;
  }
`;
const AboutImageFloat = styled.div`
  ${mq.onlysp} {
    min-width: 187px;
    right: -64px;
    bottom: -65px;
  }
`;
const AboutTextSection = styled.div`
  ${mq.onlypc} {
    max-width: 558px;
  }
  ${mq.onlysp} {
    margin-top: 113px;
  }
`;
const AboutHeading = styled(EnHeading)`
  line-height: 1.4;
  ${mq.onlypc} {
    font-size: 40px;
  }
  ${mq.onlysp} {
    font-size: 24px;
  }
`;

// Companyセクション
const AboutCompanySection = styled(AboutContentSection)`
  ${mq.onlypc} {
    margin-top: 90px;
  }
  ${mq.onlysp} {
    margin-top: 64px;
  }
`;
const AboutCompanyImageSection = styled(AboutImageSection)`
  ${mq.onlypc} {
    min-width: 645px;
    height: 622px;
    margin-left: -192px;
  }
`;
const AboutCompanyImageFloat = styled(AboutImageFloat)`
  position: absolute;
  height: auto;
  ${mq.onlypc} {
    top: 402px;
    left: 373px;
    min-width: 409px;
  }
`;
const AboutCompanyTextSection = styled(AboutTextSection)`
  ${mq.onlypc} {
    margin-left: 197px;
  }
`;
const CompanyHeading = styled(AboutHeading)`
  height: auto;

  ${mq.onlypc} {
    width: 436px;
    margin-top: 143px;
  }
  ${mq.onlysp} {
    margin-top: 60px;
    width: 236px;
    min-width: unset;
  }
`;
const AboutLinkCompany = styled(AboutLink)``;

// Studiosセクション
const AboutStudiosSection = styled(AboutContentSection)`
  ${mq.onlypc} {
    margin-top: 103px;
  }
  ${mq.onlysp} {
    margin-top: 113px;
  }
`;
const AboutStudiosImageSection = styled(AboutImageSection)`
  ${mq.onlypc} {
    min-width: 711px;
    height: 544px;
    margin-right: -204px;
  }
`;
const AboutStudiosImageFloat = styled(AboutImageFloat)`
  position: absolute;
  height: auto;

  ${mq.onlypc} {
    top: 388px;
    right: 455px;
    min-width: 344px;
  }
`;
const AboutStudiosTextSection = styled(AboutTextSection)`
  ${mq.onlypc} {
    margin-right: 46px;
    margin-left: 65px;
  }
`;
const StudiosHeading = styled(AboutHeading)`
  height: auto;
  ${mq.onlypc} {
    margin-top: 120px;
  }
  ${mq.onlysp} {
    margin-top: 60px;
  }
`;
const AboutLinkStudios = styled(AboutLink)``;

// Visionセクション
const AboutVisionSection = styled(AboutContentSection)`
  ${mq.onlypc} {
    margin-top: 202px;
  }

  ${mq.onlysp} {
    margin-top: 113px;
  }
`;
const AboutVisionImageSection = styled(AboutImageSection)`
  ${mq.onlypc} {
    min-width: 732px;
    height: 593px;
    margin-left: -303px;
  }
`;
const AboutVisionImageFloat = styled(AboutImageFloat)`
  position: absolute;
  height: auto;
  ${mq.onlypc} {
    min-width: 344px;
    top: 372px;
    left: 569px;
  }
`;
const AboutVisionTextSection = styled(AboutTextSection)`
  ${mq.onlypc} {
    margin-left: 251px;
  }
`;
const VisionHeading = styled(AboutHeading)`
  height: auto;
  ${mq.onlypc} {
    margin-top: 76px;
  }
  ${mq.onlysp} {
    margin-top: 110px;
  }
`;
const AboutLinkVision = styled(AboutLink)``;

// Cultureセクション
const AboutCultureSection = styled(AboutContentSection)`
  z-index: 1;
  ${mq.onlypc} {
    margin-top: 49px;
  }
  ${mq.onlysp} {
    margin-top: 113px;
  }
`;
const AboutCultureImageSection = styled(AboutImageSection)`
  ${mq.onlypc} {
    min-width: 636px;
    height: 593px;
    margin-right: -183px;
  }
`;
const AboutCultureImageFloat = styled(AboutImageFloat)`
  position: absolute;
  height: auto;
  ${mq.onlypc} {
    min-width: 470px;
    top: 445px;
    right: 468px;
  }
`;
const AboutCultureTextSection = styled(AboutTextSection)`
  ${mq.onlypc} {
    margin-right: 155px;
  }
`;
const CultureHeading = styled(AboutHeading)`
  height: auto;
  ${mq.onlypc} {
    margin-top: 122px;
  }
  ${mq.onlysp} {
    margin-top: 60px;
  }
`;
const AboutLinkCulture = styled(AboutLink)``;

// Corporate Sustainabilityセクション
const AboutCorporateSustainabilitySection = styled(AboutContentSection)`
  ${mq.onlypc} {
    margin-top: 315px;
    margin-bottom: 130px;
  }
  ${mq.onlysp} {
    margin-top: 244px;
    margin-bottom: 120px;
  }
`;
const AboutCorporateSustainabilityImageSection = styled.div`
  position: relative;
  ${mq.onlypc} {
    width: 470px;
    height: 248px;
    margin-left: 61px;
  }
  ${mq.onlysp} {
    width: 248.28px;
    height: 130.92px;
    margin-right: -32px;
    margin-left: auto;
  }
`;
const AboutCorporateSustainabilityVideoSection = styled.div`
  position: absolute;
  mix-blend-mode: multiply;

  ${mq.onlypc} {
    min-width: 834px;
    height: 773px;
    top: -290px;
    right: 30px;
  }
  ${mq.onlysp} {
    min-width: 467.4px;
    height: 433.2px;
    top: -147px;
    left: -280px;
  }

  div {
    height: 100%;
    pointer-events: none;
  }
  video {
    object-fit: fill;
  }
`;
const AboutCorporateSustainabilityTextSection = styled.div`
  ${mq.onlypc} {
    max-width: 573px;
    margin-left: 72px;
    margin-top: 75px;
  }
  ${mq.onlysp} {
    margin-top: 191px;
  }
`;
const CorporateSustainabilityHeading = styled(AboutHeading)`
  height: auto;
`;
const AboutLinkCorporateSustainability = styled(AboutLink)``;

const LinkArrow = styled(Arrow)`
  margin-left: 8px;
  width: 24px;
  height: 24px;
`;

const ABOUT_IMAGES = {
  pc: {
    company: {
      1: 'about/scenery/company1.jpg',
      2: 'about/scenery/company2.jpg',
    },
    culture: {
      1: 'about/scenery/culture1.jpg',
      2: 'about/scenery/culture2.jpg',
    },
    studios: {
      1: 'about/scenery/studios1.jpg',
      2: 'about/scenery/studios2.jpg',
    },
    vision: {
      1: 'about/scenery/vision1.jpg',
      2: 'about/scenery/vision2.jpg',
    },
    videoThumb: 'about/about-thumb.jpg',
  },
  sp: {
    company: {
      1: 'about/scenery/company1_sp.jpg',
      2: 'about/scenery/company2_sp.jpg',
    },
    culture: {
      1: 'about/scenery/culture1_sp.jpg',
      2: 'about/scenery/culture2_sp.jpg',
    },
    studios: {
      1: 'about/scenery/studios1_sp.jpg',
      2: 'about/scenery/studios2_sp.jpg',
    },
    vision: {
      1: 'about/scenery/vision1_sp.jpg',
      2: 'about/scenery/vision2_sp.jpg',
    },
    videoThumb: 'about/about-thumb_sp.jpg',
  },
};

const ABOUT_PARALLAX_VARIABLE = {
  pc: {
    sectionImage: -10,
    floatImage: 7,
    sustainabilityImage: -5,
  },
  sp: {
    sectionImage: -5,
    floatImage: 4,
    sustainabilityImage: -3,
  },
};

const Pages: React.FC<IndexPageProps> = () => {
  const { ref, inView } = useInView({
    rootMargin: '-50px',
    triggerOnce: true,
  });

  const [layout, setLayout] = useState<'pc' | 'sp'>('pc');
  const [aboutImages, setAboutImages] = useState(ABOUT_IMAGES.pc);
  const [aboutParallax, setAboutParallax] = useState(ABOUT_PARALLAX_VARIABLE.pc);
  const handleResize = () => {
    setLayout(window.innerWidth > breakpoint ? 'pc' : 'sp');
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize, false);
    setAboutImages(ABOUT_IMAGES[layout]);
    setAboutParallax(ABOUT_PARALLAX_VARIABLE[layout]);
  }, [layout]);

  return (
    <Body>
      <SEO title={'Vision | About'} en />
      <ContentWrapper>
        <AboutNav
          links={[
            { text: 'Company', link: '/en/about/company' },
            { text: 'Studios', link: '/en/about/studios' },
            { text: 'Vision', link: '/en/about/vision' },
            { text: 'Culture', link: '/en/about/culture' },
            { text: 'Sustainability', link: '/en/about/sustainability' },
          ]}
        />

        <AboutSection>
          <AboutCompanySection>
            <FadeUpSection moveBig>
              <AboutContentInnerEven>
                <AboutCompanyImageSection>
                  <FadeDownSection moveBig>
                    <Parallax speed={aboutParallax.sectionImage}>
                      <Img filename={aboutImages.company[1]} alt={'Company Image'} />
                    </Parallax>
                  </FadeDownSection>
                  <AboutCompanyImageFloat>
                    <Parallax speed={aboutParallax.floatImage}>
                      <Img filename={aboutImages.company[2]} alt={'Company Image'} />
                    </Parallax>
                    {/* <ReactPlayer
                    url={AboutTopCompany}
                    playing={true}
                    muted
                    playsinline
                    width={'100%'}
                    height={'100%'}
                    loop={true}
                  ></ReactPlayer> */}
                  </AboutCompanyImageFloat>
                </AboutCompanyImageSection>
                <AboutCompanyTextSection>
                  <CompanyHeading>Globally expanding Design & tech company</CompanyHeading>

                  <MainText>
                    <NewAboutText>
                      FOURDIGIT delivers digital design that links businesses with users across the world. As a
                      professional partner to businesses, we work alongside our customers in service creation, DX, and
                      digital initiatives to accelerate business growth.
                    </NewAboutText>
                  </MainText>
                  <IndexLink to={`${links[0].link}`}>
                    <AboutLinkCompany>
                      {links[0].name}
                      <LinkArrow hoverAnime />
                    </AboutLinkCompany>
                  </IndexLink>
                </AboutCompanyTextSection>
              </AboutContentInnerEven>
            </FadeUpSection>
          </AboutCompanySection>

          <AboutStudiosSection>
            <FadeUpSection moveBig>
              <AboutContentInnerOdd>
                <AboutStudiosImageSection>
                  <FadeDownSection moveBig>
                    <Parallax speed={aboutParallax.sectionImage}>
                      <Img filename={aboutImages.studios[1]} alt={'Studios Image'} />
                    </Parallax>
                  </FadeDownSection>
                  <AboutStudiosImageFloat>
                    <Parallax speed={aboutParallax.floatImage}>
                      <Img filename={aboutImages.studios[2]} alt={'Studios Image'} />
                    </Parallax>
                    {/* <ReactPlayer
                    url={AboutTopStudio}
                    playing={true}
                    muted
                    playsinline
                    width={'100%'}
                    height={'100%'}
                    loop={true}
                  ></ReactPlayer> */}
                  </AboutStudiosImageFloat>
                </AboutStudiosImageSection>

                <AboutStudiosTextSection>
                  <StudiosHeading>Getting to where design is needed</StudiosHeading>
                  <MainText>
                    <NewAboutText>
                      We are launching studios in countries where the spread of technology is growing and the power of
                      design is clearly evident in the business world, to lead the digital design market together with
                      our local teams.
                    </NewAboutText>
                  </MainText>
                  <IndexLink to={`${links[1].link}`}>
                    <AboutLinkStudios>
                      {links[1].name}
                      <LinkArrow hoverAnime />
                    </AboutLinkStudios>
                  </IndexLink>
                </AboutStudiosTextSection>
              </AboutContentInnerOdd>
            </FadeUpSection>
          </AboutStudiosSection>

          <AboutVisionSection>
            <FadeUpSection moveBig>
              <AboutContentInnerEven>
                <AboutVisionImageSection>
                  <FadeDownSection moveBig>
                    <Parallax speed={aboutParallax.sectionImage}>
                      <Img filename={aboutImages.vision[1]} alt={'Vision Image'} />
                    </Parallax>
                  </FadeDownSection>
                  <AboutVisionImageFloat>
                    <Parallax speed={aboutParallax.floatImage}>
                      <Img filename={aboutImages.vision[2]} alt={'Vision Image'} />
                    </Parallax>
                    {/* <ReactPlayer
                    url={AboutTopVision}
                    playing={true}
                    muted
                    playsinline
                    width={'100%'}
                    height={'100%'}
                    loop={true}
                  ></ReactPlayer> */}
                  </AboutVisionImageFloat>
                </AboutVisionImageSection>
                <AboutVisionTextSection>
                  <VisionHeading>Making a better future through design & tech</VisionHeading>
                  <MainText>
                    <NewAboutText>
                      We employ our strengths in design and tech as a team, for a better future. We closely engage with
                      all kinds of services, products, and brands, creating designs with value and meaning, connecting
                      with the future.
                    </NewAboutText>
                  </MainText>
                  <IndexLink to={`${links[2].link}`}>
                    <AboutLinkVision>
                      {links[2].name}
                      <LinkArrow hoverAnime />
                    </AboutLinkVision>
                  </IndexLink>
                </AboutVisionTextSection>
              </AboutContentInnerEven>
            </FadeUpSection>
          </AboutVisionSection>

          <AboutCultureSection>
            <FadeUpSection moveBig>
              <AboutContentInnerOdd>
                <AboutCultureImageSection>
                  <FadeDownSection moveBig>
                    <Parallax speed={aboutParallax.sectionImage}>
                      <Img filename={aboutImages.culture[1]} alt={'Culture Image'} />
                    </Parallax>
                  </FadeDownSection>
                  <AboutCultureImageFloat>
                    <Parallax speed={aboutParallax.floatImage}>
                      <Img filename={aboutImages.culture[2]} alt={'Culture Image'} />
                    </Parallax>
                    {/* <ReactPlayer
                    url={AboutTopCulture}
                    playing={true}
                    muted
                    playsinline
                    width={'100%'}
                    height={'100%'}
                    loop={true}
                  ></ReactPlayer> */}
                  </AboutCultureImageFloat>
                </AboutCultureImageSection>

                <AboutCultureTextSection>
                  <CultureHeading>To build a better future as a team...</CultureHeading>
                  <MainText>
                    <NewAboutText>
                      We are a team that possesses reliable skills and knowledge and comprises diverse team members of
                      different ages and nationalities. Precisely because we are a diverse design and tech company, we
                      have principles and cultures that we value.
                    </NewAboutText>
                  </MainText>
                  <IndexLink to={`${links[3].link}`}>
                    <AboutLinkCulture>
                      {links[3].name}
                      <LinkArrow hoverAnime />
                    </AboutLinkCulture>
                  </IndexLink>
                </AboutCultureTextSection>
              </AboutContentInnerOdd>
            </FadeUpSection>
          </AboutCultureSection>

          <FadeUpSection moveBig>
            <AboutCorporateSustainabilitySection>
              <AboutCorporateSustainabilityImageSection>
                <FadeDownSection moveBig>
                  <Img filename={'about/scenery/corporateSustainability.jpg'} alt={'波'} />
                </FadeDownSection>
                <AboutCorporateSustainabilityVideoSection ref={ref}>
                  <ReactPlayer
                    url={AboutTopSustainability}
                    playing={inView}
                    muted
                    playsinline
                    width={'100%'}
                    height={'100%'}
                  ></ReactPlayer>
                </AboutCorporateSustainabilityVideoSection>
              </AboutCorporateSustainabilityImageSection>

              <AboutCorporateSustainabilityTextSection>
                <CorporateSustainabilityHeading>Designing sustainability</CorporateSustainabilityHeading>

                <MainText>
                  <NewAboutText>
                    At FOURDIGIT we base our work in sustainability on the idea of Creating Shared Value(CSV). We design
                    setups that enable us to share the value of design and to continuously grow alongside the partners
                    we work with.
                  </NewAboutText>
                </MainText>
                <IndexLink to={`${links[4].link}`}>
                  <AboutLinkCorporateSustainability>
                    {links[4].name}
                    <LinkArrow hoverAnime />
                  </AboutLinkCorporateSustainability>
                </IndexLink>
              </AboutCorporateSustainabilityTextSection>
            </AboutCorporateSustainabilitySection>
          </FadeUpSection>
        </AboutSection>
      </ContentWrapper>

      <InquiryBlock en />
    </Body>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
