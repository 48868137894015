import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { mq, baseTransitionSecond, easeOut } from 'style/variables';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { slideInActive, slideIn } from 'style/mixin';
import FadeUpItem from '../fadeUpItem/index';
import { breakpoint } from 'style/variables';
import { EnHeading } from 'components/atoms/enHeading';

type AboutNavProps = {
  heading?: string;
  links?: Array<LinksObject>;
  currentPage?: string;
  size?: 'large';
};

type LinksObject = {
  text: string;
  link: string;
  active?: boolean;
};

const TitleSection = styled('div')<Pick<AboutNavProps, 'size'>>`
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;

const Container = styled.div`
  position: relative;
  ${mq.onlypc} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  ${mq.onlysp} {
    line-height: 1;
  }
`;

const Title = styled.div`
  position: relative;
  h3 {
    display: inline-block;
    font-size: 40px;
    font-family: ${fonts.enGothic};

    line-height: 1;
    letter-spacing: 0.075em;
  }
`;

const Links = styled.div`
  font-family: ${fonts.enGothic};

  line-height: 1;
  display: flex;
  align-items: center;
  ${mq.onlypc} {
    transform: translateX(16px);
  }
  ${mq.onlysp} {
    position: relative;
    margin: -27px -${sizes.margin[32]} 56px;
    padding: 0 ${sizes.margin[32]};
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  & > div {
    padding: 0 16px;
    ${mq.onlysp} {
      position: relative;
      padding: 0;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    & + div {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: ${colors.gray02};
        display: inline-block;
      }
    }
  }
`;

const AboutLink = styled(Link)`
  position: relative;
  display: block;
  padding: 4px 16px;
  border-radius: 2px;
  font-size: 16px;
  letter-spacing: 0.075em;
  text-align: center;
  overflow: hidden;
  /* text-transform: uppercase; */
  font-weight: 500;

  ${mq.onlysp} {
    min-width: 10.5em;
    margin: 27px 0;
    white-space: nowrap;
  }
  &.is-active {
    color: #fff;
    &::before {
      transform-origin: left;
      background-color: ${colors.black01};
      animation: ${slideInActive} ${baseTransitionSecond * 2}s ease 0.6s both;
    }
    &::after {
      display: none;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.gray02};
    transform-origin: right;
    transition: background-color ${baseTransitionSecond}s ${easeOut}, transform ${baseTransitionSecond}s ${easeOut};
    transform: scaleX(0);
  }
  &::after {
    animation: ${slideIn} ${baseTransitionSecond * 2}s ease 0.6s both;
  }
  ${mq.onlypc} {
    &:hover {
      &::before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

export const AboutNav: React.FC<AboutNavProps> = props => {
  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <Container>
      <Title>
        <TitleSection>
          <EnHeading>{props?.heading || 'About'}</EnHeading>
        </TitleSection>
      </Title>
      {props.links ? (
        <FadeUpItem delay={0.4}>
          <Links>
            {props.links.map((item, idx) => (
              <div key={idx}>
                <AboutLink className={item.active ? 'is-active' : undefined} to={item.link}>
                  <span>{item.text}</span>
                </AboutLink>
              </div>
            ))}
          </Links>
        </FadeUpItem>
      ) : null}
    </Container>
  );
};
